import React, { ButtonHTMLAttributes, useState } from "react";
import clsx from "clsx";
import { IconCheck } from "icons";
import Button from "components/Button";
import { clipboardCopy } from "utils";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  copyData: string;
}
const CopyButton = ({ children, onClick, copyData, ...props }: ButtonProps) => {
  const [isCopied, setIsCopied] = useState(false);

  function handleCopy() {
    if (isCopied) return;
    setIsCopied(true);
    clipboardCopy(copyData);
    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  }

  return (
    <Button
      {...props}
      className={clsx("btn", isCopied ? "!text-green pointer-events-none" : "", props.className)}
      onClick={(e) => {
        if (onClick) onClick(e);
        handleCopy();
      }}
    >
      {isCopied ? (
        <>
          <IconCheck className="w-[18px] h-[18px]" /> Copied!
        </>
      ) : (
        children
      )}
    </Button>
  );
};

export default CopyButton;
