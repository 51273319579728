import React from "react";
import ReactBeforeSliderComponent from "react-before-after-slider-component";
import clsx from "clsx";

const BeforeAfterImage = ({ selectionImages, images, mainImage, sliderPercentPosition }: any) => {
  const [selectedImageIndex, setSelectedImageIndex] = React.useState(0);

  return (
    <div className="relative">
      <ReactBeforeSliderComponent firstImage={{ imageUrl: images[selectedImageIndex] }} secondImage={{ imageUrl: mainImage }} currentPercentPosition={sliderPercentPosition ?? 50} />

      {selectionImages?.length > 0 && (
        <div className="absolute flex-center bottom-[30px] w-full">
          <div className="flex flex-col gap-2.5 p-3 bg-green rounded-xl max-w-[80%]">
            <div className="flex items-center gap-1.5">
              {selectionImages.map((image: any, index: number) => (
                <div
                  className={clsx("p-[3px] rounded-md border-[2px]", selectedImageIndex === index ? "" : "cursor-pointer border-transparent")}
                  key={"BeforeAfterImageSelect_" + index}
                  onClick={() => setSelectedImageIndex(index)}
                >
                  <img src={image} className="flex-shrink-0 aspect-square w-[60px] object-cover rounded-[3px]" />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BeforeAfterImage;
