import React from "react";

export const MOBILE_WIDTH = 430;

export const useIsMobile = ({ maxWidth = MOBILE_WIDTH }: { maxWidth?: number } = {}) => {
  const [isMobile, setIsMobile] = React.useState(screen.width <= maxWidth);

  React.useEffect(() => {
    const handleWindowResize = () => {
      const isMobileViewport = screen.width <= maxWidth;
      setIsMobile(isMobileViewport);
    };

    handleWindowResize(); // Call it initially to ensure the state is correct on mount
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, [maxWidth]);

  return isMobile;
};
