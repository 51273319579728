import React, { useRef, useState } from "react";
import Button from "components/Button";
import InputSwitch from "components/InputSwitch";
import TableComponent from "./TableComponent";
import paymentService from "api/payment/payment.service";
import clsx from "clsx";
import ContactUsModal from "components/ContactUsModal";
import Slider from "./Slider";
import { useNavigate } from "react-router-dom";
import { PATHS } from "router/config/paths";
import { getClearPath } from "utils";

const PackageCard = ({ seatCount, dataLength, isButtonsDisabled, setIsButtonsDisabled, monthlyData, yearlyData, index }: any) => {
  const [isMonthly, setIsMonthly] = React.useState(true);
  const navigate = useNavigate();

  const data = React.useMemo(() => (isMonthly ? monthlyData : yearlyData), [isMonthly]);
  const { price, product_id, price_id, period, description, name } = data;

  function handleSelectPlan() {
    if ((seatCount >= 2 && index === 0) || seatCount > 10) return;

    setIsButtonsDisabled(true);
    paymentService
      .startPayment({ product_id: product_id, price_id: price_id, seat: seatCount })
      .then((res) => (window.location.href = res.responseData[0].payment_link))
      .catch(() => {
        navigate(getClearPath(PATHS.LOGIN));
        setIsButtonsDisabled(false);
      })
      .finally(() => setIsButtonsDisabled(false));
  }

  return (
    <div className="relative flex gap-5 w-full">
      {((seatCount >= 2 && index === 0) || seatCount > 10) && <div className="pointer-events-none absolute w-full h-full bg-black bg-opacity-50 z-50" />}
      <div className={clsx("flex flex-col w-full rounded-lg", index === 0 ? "bg-pink" : index === 1 ? "bg-orange" : "bg-green")}>
        <div className="flex justify-center gap-2 rounded-lg bg-bg bg-opacity-10 py-1.5">
          {index !== dataLength - 1 ? (
            <>
              <InputSwitch leftText="monthly" rightText="yearly" onChange={(val: any) => setIsMonthly(!val)} />
              <span className="flex-center rounded-[3px] bg-bg text-pink text-headline uppercase py-[2px] px-[5px]">%10 off</span>
            </>
          ) : (
            <span className="text-headline uppercase">yearly</span>
          )}
        </div>
        <div className="flex flex-col gap-5 p-4">
          <div className="flex flex-col gap-[15px]">
            <div className="flex gap-2.5">
              <span className="text-h1 text-bg">
                $
                {index === 1 && !isMonthly
                  ? ((price * seatCount) / 12).toFixed()
                  : index === 0 && !isMonthly
                  ? (price / 12).toFixed()
                  : index === 2
                  ? (price / 12).toFixed()
                  : index === 1
                  ? price * seatCount
                  : price}
              </span>
              <div className="flex flex-col justify-center gap-[2px]">
                <span className="text-bodyMd text-bg">/month</span>
                <span className="text-bodyMd text-bg">(billed {index === 2 ? "yearly" : isMonthly ? "monthly" : "yearly"})</span>
              </div>
            </div>
            <div className="flex flex-col gap-[5px]">
              <span className="text-bg text-bodyMd">
                <span>
                  <b>
                    {(index === 1 || index === 0) && !isMonthly
                      ? (data.usage["Design Credit"] / 12).toLocaleString()
                      : index === 2
                      ? Math.round(data.usage["Design Credit"] / seatCount).toLocaleString()
                      : data.usage["Design Credit"].toLocaleString()}{" "}
                    {index === 2 ? "yearly" : "monthly"}
                  </b>{" "}
                  design credits
                  {index !== 0 && <span> per seat*</span>}
                </span>
              </span>
              <div className="w-full h-[18.2px]">
                {index === dataLength - 1 && <span className="text-[13px] italic font-normal leading-[18.2px] ">*{data.usage["Design Credit"].toLocaleString()} yearly design credits total</span>}
              </div>{" "}
            </div>
          </div>
          <div className="flex flex-col gap-[5px]">
            <h3 className="text-h3 text-bg">{name}</h3>
            <span className="text-bodyMd text-bg">{description}</span>
          </div>
        </div>
        <div className="flex flex-col gap-5 p-4 mt-auto">
          <ul className="flex flex-col gap-[14px]">
            <li>
              <span className="flex items-center gap-2.5 text-bg text-bodySm">
                <div className="bg-bg rounded-full h-1.5 w-1.5" />
                <span>
                  <b>{(index === 1 || index === 0) && !isMonthly ? (data.usage["Design Credit"] / 12).toLocaleString() : data.usage["Design Credit"].toLocaleString()}</b>{" "}
                  {index === 2 ? "yearly" : "monthly"} design credits
                  {index !== 0 && <span> per seat</span>}
                </span>
              </span>
            </li>
            <li>
              <span className="flex items-center gap-2.5 text-bg text-bodySm">
                <div className="bg-bg rounded-full h-1.5 w-1.5" />
                <span>
                  <b>{data.usage["Max Team Member"] === -1 ? "Unlimited" : data.usage["Max Team Member"]}</b> team members
                </span>
              </span>
            </li>
            <li>
              <span className="flex items-center gap-2.5 text-bg text-bodySm">
                <div className="bg-bg rounded-full h-1.5 w-1.5" />
                <span>
                  <b>{(index === 1 || index === 0) && !isMonthly ? data.fashion_style["Style Training"] / 12 : data.fashion_style["Style Training"]}</b> monthly train credits
                  {index !== 0 && <span> per seat</span>}
                </span>
              </span>
            </li>
          </ul>
          <Button className="btn-primary !bg-black !text-white" disabled={isButtonsDisabled} onClick={handleSelectPlan}>
            {index === 0 || index === 1 ? "Start Now" : "Start 7-Day Free Trial"}
          </Button>
        </div>
      </div>
    </div>
  );
};

const ChoosePlan = () => {
  const tablesContainerRef = useRef<HTMLDivElement>(null);
  const [packageList, setPackageList] = React.useState<any>(null);
  const [isButtonsDisabled, setIsButtonsDisabled] = useState(false);
  const [showContactUsModal, setShowContactUsModal] = React.useState(false);
  const [seatCount, setSeatCount] = useState(1);

  React.useEffect(() => {
    const originalOverflow = document.body.style.overflow;
    document.body.style.overflow = "hidden";

    const handleEscapeKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        // onClose();
      }
    };

    document.addEventListener("keydown", handleEscapeKeyPress);

    return () => {
      document.removeEventListener("keydown", handleEscapeKeyPress);
      document.body.style.overflow = originalOverflow;
    };
  }, []);

  React.useEffect(() => {
    paymentService.getPackageList().then((res) => {
      setPackageList(res.data.package_list);
    });
  }, []);

  return (
    <div className="flex justify-center h-full w-full overflow-x-hidden overflow-y-scroll no-scrollbar">
      {showContactUsModal && <ContactUsModal show={showContactUsModal} onClose={() => setShowContactUsModal(false)} type="enterprise" />}
      <div className="flex flex-col h-full w-[70%] pt-[60px] gap-10 items-center">
        <div className="flex flex-col gap-[60px]">
          <div className="flex items-center flex-col gap-5">
            <h1 className="text-h1 text-white">Choose a Plan</h1>
            <span className="text-bodyMd text-grey">Unlock the full potential of your fashion designs with Refabric`s AI-powered.</span>
          </div>
          <div className="flex flex-col gap-5">
            <Slider
              min={1}
              max={11}
              step={1}
              initialValue={1}
              onChange={function (value: number): void {
                setSeatCount(value);
              }}
              marks={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]}
              markClassName="plan-mark"
              thumbClassName="plan-thumb"
              trackClassName="plan-track"
            />
            <span className="w-full text-center text-white text-headline uppercase">
              {seatCount > 10 ? "10+" : seatCount} seat{seatCount > 1 ? "s" : ""}
            </span>
          </div>
        </div>
        <Button
          className="text-bodyMd text-grey underline"
          onClick={() => {
            if (tablesContainerRef) tablesContainerRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
          }}
        >
          See full list of features
        </Button>
        {seatCount !== 11 && (
          <div className="flex gap-5 w-full">
            {packageList?.monthly?.map((item: any, index: number) => (
              <PackageCard
                key={item?.name}
                seatCount={seatCount}
                index={index}
                dataLength={packageList.monthly.length}
                isButtonsDisabled={isButtonsDisabled}
                setIsButtonsDisabled={setIsButtonsDisabled}
                monthlyData={packageList.monthly[index]}
                yearlyData={packageList.yearly[index]}
              />
            ))}
          </div>
        )}
        <div className="flex w-full p-4 rounded-lg border border-bg-3">
          <div className="flex flex-col gap-10 w-1/2">
            <div className="flex flex-col gap-[5px]">
              <h3 className="text-h3 text-white">Enterprise</h3>
              <span className="text-bodyMd text-grey">Personalized service for your business</span>
            </div>
            <div>
              <ul className="flex flex-col gap-[14px]">
                <li>
                  <span className="flex items-center gap-2.5 text-white text-bodySm">
                    <div className="bg-white rounded-full h-1.5 w-1.5" />
                    <b>Custom</b> design credits
                  </span>
                </li>
                <li>
                  <span className="flex items-center gap-2.5 text-white text-bodySm">
                    <div className="bg-white rounded-full h-1.5 w-1.5" />
                    <b>Unlimited</b> team members
                  </span>
                </li>
                <li>
                  <span className="flex items-center gap-2.5 text-white text-bodySm">
                    <div className="bg-white rounded-full h-1.5 w-1.5" />
                    <b>Custom</b> monthly train credits
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="flex flex-col justify-between w-1/2">
            <div className="flex flex-col gap-[15px]">
              <h2 className="text-h2 text-white">Custom</h2>
              <h5 className="text-h5 text-white">Looking for a custom solution? Let`s Talk!</h5>
            </div>
            <Button className="btn-primary !bg-white" onClick={() => setShowContactUsModal(true)}>
              Contact Us1
            </Button>
          </div>
        </div>
        {packageList !== null && (
          <div className="flex flex-col mt-5" id="table-container" ref={tablesContainerRef}>
            <div className="w-full">
              <table className="w-full text-white border-separate border-spacing-4 mx-4">
                <thead></thead>
                <tbody>
                  <tr key="header" className="">
                    <td className="text-h4  -ml-4 py-2.5"></td>
                    {packageList?.monthly.map((item: any, idx: number) => (
                      <td key={idx} className={clsx("text-h4 text-center w-[264px] p-2.5", idx === 0 ? "text-pink" : idx === 1 ? "text-orange" : idx === 2 ? "text-green" : "text-white")}>
                        {item.name}
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="flex w-full bg-bg-2 text-h5 text-white rounded-full px-[15px] py-3">Usage</div>
            <TableComponent data={packageList?.monthly} objectKey="usage" headers={Object.keys(packageList?.monthly[0]?.usage)} />
            <div className="flex w-full bg-bg-2 text-h5 text-white rounded-full px-[15px] py-3">Fashion Style</div>
            <TableComponent data={packageList?.monthly} objectKey="fashion_style" headers={Object.keys(packageList?.monthly[0]?.fashion_style)} />
            <div className="flex w-full bg-bg-2 text-h5 text-white rounded-full px-[15px] py-3">Support</div>
            <TableComponent data={packageList?.monthly} objectKey="support" headers={Object.keys(packageList?.monthly[0]?.support)} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ChoosePlan;
