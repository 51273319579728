import { ImageMobileWarning } from "images";
import React from "react";

const MobileWarning = () => {
  return (
    <div className="p-10 flex-center flex-1">
      <div className="flex flex-center flex-col gap-[30px] w-[325px]">
        <img src={ImageMobileWarning} className="w-[240px] h-[166px]" />
        <h5 className="text-h5 text-white text-center">Refabric doesn`t support mobile devices yet. Please use a desktop device for the best experience.</h5>
      </div>
    </div>
  );
};

export default MobileWarning;
