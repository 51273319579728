import React from "react";
import clsx from "clsx";
import { IconWarning } from "icons";

interface ITextarea {
  icon?: React.ReactNode;
  className?: string;
  containerClassName?: string;
  children: string;
  error: string;
  PlusAIcomponent: React.FunctionComponent;

  [key: string]: any;
}

const Textarea = ({ className, containerClassName, icon, error, PlusAIcomponent, ...etc }: ITextarea, ref: any) => {
  return (
    <>
      <div className={clsx("input-container flex flex-row gap-2 p-4", "border border-bg-3 px-2.5 py-3 rounded-[5px]", containerClassName)}>
        <textarea ref={ref} className={clsx("input overflow-y-scroll resize-y", className)} {...etc}></textarea>
        {PlusAIcomponent ? <div className="flex flex-1 h-full">{PlusAIcomponent}</div> : <></>}
        {icon}
      </div>
      {error && (
        <span className="flex text-orange text-h6 items-center gap-[5px]">
          <IconWarning className="h-[17px] w-[17px]" />
          {error}
        </span>
      )}
    </>
  );
};

export default React.forwardRef(Textarea);
