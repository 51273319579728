import React, { useEffect } from "react";
import { getAuthTokenFromLocalStorage } from "hooks/useAuthToken";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "store";
import NotAuthorized from "pages/Layout/NotAuthorized/NotAuthorized";
import { PATHS } from "./config/paths";
import { getClearPath } from "utils";

const AuthorizationPage = ({ children, requireLogin, paidPlanNeeded, routePath }: any) => {
  const hasAuthToken = getAuthTokenFromLocalStorage();
  const navigate = useNavigate();

  const { isAuthorized, isCheckingToken } = useAppSelector((state) => state.common);

  useEffect(() => {
    if (!hasAuthToken && requireLogin && !isCheckingToken) {
      navigate(getClearPath(PATHS.LOGIN));
    }
  }, [hasAuthToken, isCheckingToken]);

  if (isCheckingToken && (routePath === PATHS.LOGIN || routePath === PATHS.SIGNUP)) {
    return <></>;
  }

  if (hasAuthToken && !isCheckingToken && (routePath === PATHS.LOGIN || routePath === PATHS.SIGNUP)) {
    // wait 1 second to prevent flickering
    setTimeout(() => {
      navigate(PATHS.HOME);
    }, 1000);

    return <></>;
  }

  if (!requireLogin) return children;

  if (!hasAuthToken) {
    navigate("/login");

    return <></>;
  } else if (!isAuthorized && paidPlanNeeded) return isCheckingToken ? <></> : <NotAuthorized />;

  return children;
};

export default AuthorizationPage;
