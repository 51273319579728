import { createSlice } from "@reduxjs/toolkit";

export const designSlice = createSlice({
  name: "create",
  initialState: {
    masks: [] as Array<{ id: any; mask: any }>,
  },
  reducers: {
    addMask: (state, action) => {
      state.masks.push(action.payload);
    },
  },
  extraReducers: {},
});

export const { addMask } = designSlice.actions;

export default designSlice.reducer;
