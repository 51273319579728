import clsx from "clsx";
import Button from "components/Button";
import Search from "components/Search";
import Tab from "components/Tab";
import { TutorialType } from "components/Tutorial/components/TutorialItem";
import { DesignTutorialData, EditImageTutorialData, TrainTutorialData } from "components/Tutorial/TutorialData";
import { IconClose, IconCollection, IconDesign, IconDocuments, IconExternal, IconPlay, IconTip, IconTrain } from "icons";
import React from "react";
import { useDispatch } from "react-redux";
import { setTutorial } from "store/commonSlice";

const Resources = [
  {
    title: "Getting Started",
    description: "Learn the basics of the tool, including how to navigate and begin your design journey.",
    link: "https://explorefeatures.refabric.com/getting-started",
    image: "",
  },
  {
    title: "Trainings",
    description: "Discover how AI training works and how to customize it to suit your brand’s needs.",
    link: "https://explorefeatures.refabric.com/trainings/start-a-new-training",
    image: "",
  },
  {
    title: "Design",
    description: "Explore the design screen with tips, techniques, and tricks to create stunning designs.",
    link: "https://explorefeatures.refabric.com/design/editor",
    image: "",
  },
  {
    title: "Prompt to Design ",
    description: "Explore examples of how prompts transform into stunning designs, showcasing creativity and the potential of AI-driven creation.",
    link: "https://explorefeatures.refabric.com/prompt-to-design-showcase",
    image: "",
  },
  {
    title: "Prompting Tips",
    description: "Unlock expert tips and techniques for crafting effective prompts, maximizing creativity and achieving optimal design results.",
    link: "https://explorefeatures.refabric.com/prompting-tips",
    image: "",
  },
];

const HelpCenter = ({ show, onClose }: any) => {
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = React.useState(0);

  const Tutorials = [
    {
      icon: <IconDesign className={clsx("text-pink w-5 h-5")} />,
      title: "Create a new design",
      description: "Unleash your creativity. Design new fashion styles and patterns with Refabric’s powerful AI.",
      time: "~5 min",
      onClick: () => dispatch(setTutorial({ show: true, type: TutorialType.Design, data: DesignTutorialData })),
    },
    {
      icon: <IconTrain className={clsx("text-green w-5 h-5")} />,
      title: "Train your first model",
      description: "Kickstart your journey by training a AI model. It's your first step to bespoke fashion design.",
      time: "~5 min",
      onClick: () => dispatch(setTutorial({ show: true, type: TutorialType.Train, data: TrainTutorialData })),
    },
    {
      icon: <IconCollection className={clsx("text-orange w-5 h-5")} />,
      title: "Edit Image",
      description: "Assemble your designs into a stunning collection.",
      time: "~2 min",
      onClick: () => dispatch(setTutorial({ show: true, type: TutorialType.EditImage, data: EditImageTutorialData })),
    },
  ];

  const ResourcesItem = ({ title, description, link, image }: any) => {
    return (
      <a href={link} target="_blank" rel="noreferrer">
        <div className="flex flex-col gap-3 p-3 border border-bg-3 rounded">
          {/* <img src={image} /> */}

          <div className="flex items-center justify-between">
            <h6 className="text-h6 text-white">{title}</h6>
            <IconExternal className="text-white" />
          </div>

          <span className="text-bodySm text-grey">{description}</span>
        </div>
      </a>
    );
  };

  const ResourcesComponent = () => {
    return (
      <div className="flex flex-col gap-2">
        <Search />

        {Resources.map((item, index) => (
          <ResourcesItem key={index} {...item} />
        ))}
      </div>
    );
  };

  const TutorialsItem = ({ icon, title, description, time, onClick }: any) => {
    return (
      <div className="flex flex-col gap-[15px] p-3 border border-bg-3 rounded">
        <div className="flex gap-2.5 items-center">
          {icon}
          <h5 className="text-h5 text-white">{title}</h5>
          <span className="text-bodySm text-grey">{time}</span>
        </div>

        <span className="text-bodySm text-grey ml-[30px]">{description}</span>
        <Button className="btn-primary w-fit ml-[30px]" onClick={onClick}>
          <IconPlay className="w-[18px] h-[18px] flex-shrink-0" />
          Get Started
        </Button>
      </div>
    );
  };

  const TutorialsComponent = () => {
    return (
      <div className="flex flex-col gap-2">
        {Tutorials.map((item, index) => (
          <TutorialsItem key={index} {...item} />
        ))}
      </div>
    );
  };

  return (
    <div
      className="bg-bg-2 border-l border-bg-3"
      style={{
        position: "absolute",
        height: "calc(100vh - 86px)",
        width: "320px",
        top: "86px",
        right: 0,
        // transform: `translateX(${show ? "0" : "100%"})`,
        // transition: "transform 0.3s ease-in-out",
        overflowY: "auto",
        visibility: show ? "visible" : "hidden",
      }}
    >
      <div className="flex justify-between items-center p-2 bg-bg">
        <span className="text-headline uppercase text-white">help center</span>
        <IconClose className="cursor-pointer text-white w-[14px] h-[14px]" onClick={onClose} />
      </div>

      <div className="flex flex-col p-2.5 gap-4">
        <Tab
          initTab={0}
          onChange={(id) => {
            setSelectedItem(id);
          }}
        >
          <Tab.Item id={0}>
            <div className="flex-center gap-[5px] items-center">
              <IconDocuments className="w-[18px] h-[18px]" />
              Resources
            </div>
          </Tab.Item>

          <Tab.Item id={1}>
            <div className="flex-center gap-[5px] items-center">
              <IconTip className="w-[18px] h-[18px]" />
              Tutorials
            </div>
          </Tab.Item>
        </Tab>

        {selectedItem === 0 && <ResourcesComponent />}
        {selectedItem === 1 && <TutorialsComponent />}
      </div>
    </div>
  );
};

export default HelpCenter;
