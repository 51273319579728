import * as React from "react";

import "./InputSwitch.css";
import clsx from "clsx";

const InputSwitch = ({ className, leftText, rightText, ...etc }: any, ref: any) => {
  const [isRightSelected, setIsRightSelected] = React.useState(false);
  function handleChange(e: any) {
    const _checked = e.target.checked; //true => right , false => left
    setIsRightSelected(_checked);
    if (etc.onChange) etc.onChange(_checked);
  }

  return (
    <div className="flex items-center h-fit gap-2">
      {leftText && <span className={clsx("uppercase text-headline text-bg", isRightSelected ? "text-opacity-50" : "")}>{leftText}</span>}
      <label className={clsx("input-switch", className)}>
        <input ref={ref} type="checkbox" {...etc} className="peer" onChange={(e) => handleChange(e)} />
        <span className="slider peer-disabled:cursor-default" />
      </label>
      {rightText && <span className={clsx("uppercase text-headline text-bg", !isRightSelected ? "text-opacity-50" : "")}>{rightText}</span>}
    </div>
  );
};

export default React.forwardRef(InputSwitch);
