import React, { useEffect, useState } from "react";
import ReactSlider from "react-slider";
import "./planslider.css";
import { IconMultipleDot } from "icons";
import clsx from "clsx";
import Tooltip from "components/Tooltip";

interface SliderProps {
  min: number;
  max: number;
  step: number;
  initialValue: number;
  onChange: (value: number) => void;
  disabled?: boolean;
  maxLimit?: number;
  minLimit?: number;
  marks?: any;
  markClassName?: string;
  thumbClassName?: string;
  trackClassName?: string;
}

const Slider: React.FC<SliderProps> = ({ maxLimit = 100, minLimit = 0, min, max, step, initialValue, onChange, disabled, marks, markClassName, thumbClassName, trackClassName = "example-track" }) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleSliderChange = (newValue: number) => {
    if (minLimit !== 0) {
      setValue(minLimit);
      onChange(minLimit);
    } else if (newValue > maxLimit) {
      setValue(maxLimit);
      onChange(maxLimit);
    } else {
      setValue(newValue);
      onChange(newValue);
    }
  };

  return (
    <div className="flex-1">
      <ReactSlider
        value={value}
        min={min}
        max={max}
        disabled={disabled}
        step={step}
        className="flex items-center h-4"
        trackClassName={trackClassName}
        renderThumb={(props, state) => {
          return (
            <div {...props} className={clsx("thumb", thumbClassName)}>
              <IconMultipleDot className="text-black" />
            </div>
          );
        }}
        // thumbClassName={thumbClassName} //"thumb"
        onChange={handleSliderChange}
        marks={marks}
        markClassName={markClassName}
      />
    </div>
  );
};

export default Slider;
