import { createSlice } from "@reduxjs/toolkit";

export const historySlice = createSlice({
  name: "create",
  initialState: {
    selectedItems: [] as any,
  },
  reducers: {
    addSelectedItem: (state, action) => {
      state.selectedItems.push(action.payload);
    },
    removeSelectedItem: (state, action) => {
      state.selectedItems = state.selectedItems.filter((item: any) => item.id !== action.payload);
    },
    clearSelectedItems: (state) => {
      state.selectedItems = [];
    },
  },
  extraReducers: {},
});

export const { addSelectedItem, removeSelectedItem, clearSelectedItems } = historySlice.actions;

export default historySlice.reducer;
