import React from "react";
import { useIsMobile } from "hooks/useIsMobile";
import MobileWarning from "components/MobileWarning";
import { LogoRefabric } from "icons";

const MobileWarningPage = ({ children }: any) => {
  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <main className="bg-bg min-h-screen flex flex-col">
        <div className="flex-center w-full py-[23px] border-b border-border">
          <LogoRefabric />
        </div>
        <MobileWarning />
      </main>
    );
  }

  return children;
};

export default MobileWarningPage;
