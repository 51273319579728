import Button from "components/Button";
import Modal from "components/Modal";
import { ImageUpdateModal } from "images";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PATHS } from "router/config/paths";
import { useAppSelector } from "store";
import { hideUpdateModal } from "store/commonSlice";
import { getClearPath } from "utils";

const UpdateModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showUpdateModal } = useAppSelector((state) => state.common);

  function onClose() {
    dispatch(hideUpdateModal());
  }

  return (
    <Modal onClose={onClose} show={showUpdateModal} bodyClassName="bg-bg-2 !max-w-[480px] !border-bg-3">
      <div className="flex flex-col gap-[23px] p-6">
        <h2 className="text-h2 text-white">New Feature Update!</h2>

        <img src={ImageUpdateModal} />
        <span className="text-bodyMd text-white">
          Easily apply your garment images to AI models from our diverse catalog, transforming your designs into lifelike, dynamic visualizations across various model styles.
          <br />
          <br />
          Streamline your photoshooting process and showcase your creations with stunning, AI-enhanced visuals that capture attention and elevate your brand.
        </span>
        <div className="flex flex-col gap-2">
          <Button
            className="btn-primary"
            onClick={() => {
              onClose();

              navigate(getClearPath(PATHS.PHOTOSHOOT));
            }}
          >
            Try Now
          </Button>
          <Button className="btn-secondary" onClick={onClose}>
            Close
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default UpdateModal;
