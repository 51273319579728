import React, { useState } from "react";
import Button from "components/Button";
import Input from "components/Input";
import Modal from "components/Modal";
import { IconPlay, IconSupport } from "icons";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { setTutorial } from "store/commonSlice";
import { TutorialType } from "components/Tutorial/components/TutorialItem";
import { DesignTutorialData } from "components/Tutorial/TutorialData";
import utilsService from "api/utils/utils.service";
import { useAppSelector } from "store";

const SupportCard = ({ title, text, Icon, button }: any) => (
  <div className="flex w-[250px] gap-5 border border-bg-3 rounded-[5px]">
    <div className="flex flex-col bg-bg rounded-[5px]">
      <div className="p-2 bg-bg-3 text-h6 text-white rounded-t-[4px]">{title}</div>
      <div className="flex flex-col gap-5 w-full p-3">
        {Icon}
        <span className="text-bodySm text-white">{text}</span>
      </div>
      <div className="flex bg-bg-2 gap-2.5 p-2.5 border-t border-bg-3 rounded-b-[4px]">{button}</div>
    </div>
  </div>
);

const CaseSubmitted = ({ onClose, show }: any) => (
  <Modal onClose={onClose} show={show} bodyClassName="!rounded-[5px] !border-none">
    <div className="flex flex-col bg-bg w-[320px] border border-bg-3 rounded-[5px]">
      <div className="p-2 bg-bg-3 text-h6 text-white rounded-t-[4px]">Case #000001</div>
      <div className="flex flex-col gap-3 w-full rounded-md p-3">
        <span className="text-bodySm text-white">Your case has been submitted! Our customer support team will contact you as soon as possible.</span>
      </div>
      <div className="flex bg-bg-2 gap-2.5 p-2.5 border-t border-bg-3 rounded-b-[4px]">
        <Button className="btn-secondary w-full" onClick={onClose}>
          Close
        </Button>
      </div>
    </div>
  </Modal>
);

const schema = yup
  .object({
    feature: yup.string().required("Please fill."),
    subject: yup.string().required("Please fill."),
    description: yup.string().required("Please fill."),
  })
  .required();

const Support = () => {
  const dispatch = useDispatch();
  const { user } = useAppSelector((state) => state.common);
  const [showCaseSubmitted, setShowCaseSubmitted] = useState(false);
  const [showCaseForm, setShowCaseForm] = useState(false);
  const [submitCaseDisabeld, setSubmitCaseDisabeld] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      feature: "",
      subject: "",
      description: "",
    },
  });

  const onValid = async (data: any) => {
    setSubmitCaseDisabeld(true);
    utilsService
      .contactUs({
        first_name: user.name ?? "",
        last_name: "",
        email: user.email ?? "",
        company: user.work ?? "",
        company_size: "",
        message: data.feature + " " + data.subject + " " + data.description,
        type: "account_support",
      })
      .then(() => {
        setShowCaseSubmitted(true);
        setSubmitCaseDisabeld(false);
      })
      .catch((e) => {
        console.log(e);
        setSubmitCaseDisabeld(false);
      });
  };

  const onHandleSubmit = () => {
    handleSubmit(
      (data) => onValid(data),
      (e) => console.log(e)
    )();
  };

  return (
    <div className="flex flex-col w-fit items-center mx-auto gap-10">
      <CaseSubmitted show={showCaseSubmitted} onClose={() => setShowCaseSubmitted(false)} />
      <h2 className="text-h2 text-white">How can we help?</h2>
      <div className="flex justify-center gap-5 w-full">
        {/* <SupportCard
          title="Join Community"
          text="Join our discord channel and get help from like-minded designers."
          Icon={<IconDiscord className="w-8 h-8 text-purple" />}
          button={
            <Button className="btn-primary-small !bg-purple w-full" onClick={() => window.open("https://discord.gg/rSuT6JK6", "_blank", "noopener,noreferrer")}>
              Join Discord
            </Button>
          }
        /> */}
        <SupportCard
          title="Refabric Academy"
          text="Discover solutions through our tutorial videos"
          Icon={<IconPlay className="w-8 h-8 text-green" />}
          button={
            <Button className="btn-primary-small w-full" onClick={() => dispatch(setTutorial({ show: true, type: TutorialType.Design, data: DesignTutorialData }))}>
              Go to Academy
            </Button>
          }
        />
        <SupportCard
          title="Customer Support"
          text="Submit a case directly to our customer support team."
          Icon={<IconSupport className="w-8 h-8 text-white" />}
          button={
            <Button className="btn-primary-small !bg-white w-full" onClick={() => setShowCaseForm((prev) => !prev)}>
              Submit a Case
            </Button>
          }
        />
      </div>
      {showCaseForm && (
        <div className="flex flex-col bg-bg w-full border border-bg-3 rounded-[5px]">
          <div className="p-2 bg-bg-3 text-h6 text-white rounded-t-[4px]">Submit a Case</div>
          <div className="flex flex-col gap-3 w-full rounded-md p-3">
            <div className="flex flex-col gap-2.5">
              <span className="text-h7 text-white">Which feature you need help with?</span>
              <Input {...register("feature")} error={errors.feature?.message} />
            </div>
            <div className="flex flex-col gap-2.5">
              <span className="text-h7 text-white">Subject</span>
              <Input {...register("subject")} error={errors.subject?.message} />
            </div>
            <div className="flex flex-col gap-2.5">
              <span className="text-h7 text-white">Description</span>
              <Input {...register("description")} error={errors.description?.message} />
            </div>
          </div>
          <div className="flex bg-bg-2 gap-2.5 p-2.5 justify-end border-t border-bg-3 rounded-b-[5px]">
            <Button className="btn-secondary-small w-fit" onClick={() => setShowCaseForm(false)}>
              Cancel
            </Button>
            <Button className="btn-primary-small w-fit" disabled={submitCaseDisabeld} onClick={onHandleSubmit}>
              Submit Case
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Support;
