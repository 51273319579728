/* eslint-disable react/no-unescaped-entities */
import React, { useState } from "react";
import Input from "components/Input";
import Button from "components/Button";
import Modal from "components/Modal";
import Textarea from "components/Textarea";
import Select from "components/Select";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import utilsService from "api/utils/utils.service";
import clsx from "clsx";
import { ImageSuccessLogo } from "images";

const schema = yup
  .object({
    firstName: yup.string().required("Please fill."),
    lastName: yup.string().required("Please fill."),
    businessEmail: yup.string().required("Please enter a valid e-mail address!").email("Please enter a valid e-mail address!"),
    companyName: yup.string().required("Please fill."),
    companySize: yup.string().required("Please fill."),
    message: yup.string().required("Please fill."),
  })
  .required();

const ContactUsForm = ({ show, onClose, type }: { show: boolean; onClose: () => void; type: string }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [sendDisabled, setSendDisabled] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: "",
      lastName: "",
      businessEmail: "",
      companyName: "",
      companySize: "1-9",
      message: "",
    },
  });

  const onValid = async (data: any) => {
    utilsService
      .contactUs({
        first_name: data.firstName ?? "",
        last_name: data.lastName ?? "",
        email: data.businessEmail ?? "",
        company: data.companyName ?? "",
        company_size: data.companySize ?? "",
        message: data.message ?? "",
        type: type,
      })
      .then(() => {
        setIsSubmitted(true);
        setSendDisabled(false);
      });
  };

  const onHandleSubmit = () => {
    setSendDisabled(true);
    handleSubmit(
      (data) => onValid(data),
      (e) => {
        console.log(e);
        setSendDisabled(false);
      }
    )();
  };

  return (
    <Modal onClose={onClose} show={show} bodyClassName={clsx("!border-black overflow-hidden overflow-y-scroll no-scrollbar", !isSubmitted ? "w-[520px]" : "w-[480px]")}>
      {!isSubmitted ? (
        <div className="flex flex-col gap-10 p-5 bg-white max-w-[520px] w-full">
          <h2 className="text-h2 w-full text-center ">Contact Us</h2>
          <div className="flex flex-col gap-3 w-full">
            <div className="flex gap-3 w-full">
              <div className="flex flex-col gap-2.5 w-full">
                <span className="text-h7 text-black">First Name</span>
                <Input {...register("firstName")} containerClassName="!bg-white" className="text-black" error={errors.firstName?.message} />
              </div>
              <div className="flex flex-col gap-2.5 w-full">
                <span className="text-h7 text-black">Last Name</span>
                <Input {...register("lastName")} containerClassName="!bg-white" className="text-black" error={errors.lastName?.message} />
              </div>
            </div>
            <div className="flex flex-col gap-2.5">
              <span className="text-h7 text-black">Business Email</span>
              <Input {...register("businessEmail")} containerClassName="!bg-white" className="text-black" error={errors.businessEmail?.message} />
            </div>
            <div className="flex flex-col gap-2.5">
              <span className="text-h7 text-black">Company Name</span>
              <Input {...register("companyName")} containerClassName="!bg-white" className="text-black" error={errors.companyName?.message} />
            </div>
            <div className="flex flex-col gap-2.5">
              <span className="text-h7 text-black">Company Size</span>
              <Select
                isLightMode={true}
                defaultId={1}
                className="w-full !text-black"
                options={[
                  { id: 1, title: "1-9" },
                  { id: 2, title: "10-49" },
                  { id: 3, title: "50-249" },
                  { id: 4, title: "250-999" },
                  { id: 5, title: "1000+" },
                ]}
                onChange={(val) => {
                  setValue("companySize", val.title);
                }}
              />
            </div>
            <div className="flex flex-col gap-2.5">
              <span className="text-h7 text-black">Message</span>
              <Textarea {...register("message")} containerClassName="!bg-white" error={errors.message?.message} className="h-[100px] !resize-none !text-black" />
            </div>
            <div className="flex gap-2.5">
              <Button className="btn-secondary !text-black hover:!text-white hover:!bg-black w-full" onClick={onClose}>
                Cancel
              </Button>
              <Button disabled={sendDisabled} className="btn-primary w-full" onClick={onHandleSubmit}>
                Send
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center gap-10 bg-white max-w-[480px] w-full p-5">
          <img src={ImageSuccessLogo} className="w-[75px]" />
          <h2 className="text-h2 text-center">We've received your message!</h2>
          <span className="text-bodyMd text-center">Your message has been submitted! Our customer support team will contact you as soon as possible.</span>
          <Button className="btn-primary w-full" onClick={onClose}>
            Close
          </Button>
        </div>
      )}
    </Modal>
  );
};

export default ContactUsForm;
