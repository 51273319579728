import { RefabricProURL } from "../index";
import { ApiResponse } from "../HttpClient";
import { ITeamsSendInviteRequest, ITeamsAddMemberRequest, ITeamsEditMemberRoleRequest } from "./teams.type";

export default {
  async sendInvite(data: ITeamsSendInviteRequest): Promise<ApiResponse<any>> {
    return await RefabricProURL.post("teams/send_invite", data, {});
  },
  async acceptInvite(link_token: string, params: { email: string }): Promise<ApiResponse<any>> {
    return await RefabricProURL.get(`teams/accept_invite/${link_token}`, { params });
  },
  async addMember(data: ITeamsAddMemberRequest): Promise<ApiResponse<any>> {
    return await RefabricProURL.post("teams/member", data, {});
  },
  async editMemberRole(member_id: string, data: ITeamsEditMemberRoleRequest): Promise<ApiResponse<any>> {
    return await RefabricProURL.put(`teams/${member_id}/role`, data, {});
  },
  async deleteMember(member_id: string): Promise<ApiResponse<any>> {
    return await RefabricProURL.delete(`teams/${member_id}`, {});
  },
  async getTeamMembers(): Promise<ApiResponse<any>> {
    return await RefabricProURL.get(`teams/members`, {});
  },
  async editTeamMembersRoles(items: Array<{ uid: number; role: string }>): Promise<ApiResponse<any>> {
    return await RefabricProURL.put(`teams/roles`, { items }, {});
  },
  async deleteTeamMembers(ids: Array<{ uid: string }>): Promise<ApiResponse<any>> {
    return await RefabricProURL.delete(`teams/delete`, { data: { items: ids } });
  },
  async getTeamInfo(): Promise<ApiResponse<any>> {
    return await RefabricProURL.get(`teams/team_info`, {});
  },
};
