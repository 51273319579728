import React from "react";

const ProgressBar = ({ maxAmount, currentAmount }: any) => {
  return (
    <div className="relative h-1 w-full bg-black rounded-full">
      <div className="h-1 bg-green rounded-full" style={{ width: maxAmount === 0 ? "0px" : (currentAmount / maxAmount) * 100 + "%" }} />
    </div>
  );
};

export default ProgressBar;
