import useS3 from "hooks/useS3";
import React from "react";

const InputUpload = ({ children, callback }: any) => {
  const [isUploading, setIsUploading] = React.useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [error, setError] = React.useState("");

  const { handleUpload } = useS3();

  const handleIconClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  return (
    <div onClick={handleIconClick}>
      <input
        ref={inputRef}
        accept="image/png, image/jpg, image/jpeg"
        style={{ display: "none" }}
        type="file"
        onChange={async (e) => {
          setError("");

          const files = e.target.files;

          if (!files) return;
          if (files.length === 0) return;

          try {
            if (isUploading || files?.length === 0) return;
            setIsUploading(true);
            if (files && files.length > 0) {
              const _files = new Array(1);
              _files[0] = files[0];

              const urlArray = await handleUpload({ files: _files });
              setIsUploading(false);
              if (callback) callback(urlArray[0].Location);
              if (inputRef.current) inputRef.current.value = "";
            }
          } catch (error) {
            setIsUploading(false);
          }
        }}
      />

      {children}
    </div>
  );
};

export default InputUpload;
