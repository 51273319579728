import { configureStore } from "@reduxjs/toolkit";
import { commonSlice } from "./commonSlice";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { historySlice } from "./historySlice";
import { designSlice } from "./designSlice";
import { snackbarSlice } from "./snackbarSlice";

const store = configureStore({
  reducer: {
    common: commonSlice.reducer,
    history: historySlice.reducer,
    design: designSlice.reducer,
    snackbar: snackbarSlice.reducer,
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
