import React, { useRef, useState } from "react";
import Button from "components/Button";
import Checkbox from "components/CheckBox/Checkbox";
import Modal from "components/Modal";
import Search from "components/Search";
import Table, { ITableHeader } from "components/Table/Table";
import { IconCheck, IconMore, IconPlus, IconTrash, IconWarning } from "icons";
import AddUser from "./AddUser";
import Select from "./Select";
import { useClickOutside } from "hooks/useClickOutside";
import clsx from "clsx";
import teamsService from "api/teams/teams.service";
import { useAppSelector } from "store";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSnackbar } from "store/commonSlice";

export enum RoleType {
  Admin,
  Editor,
  Viewer,
}

const OutOfCredit = ({ onClose, show }: any) => {
  const navigate = useNavigate();

  return (
    <Modal onClose={onClose} show={show} bodyClassName="!rounded-[5px] !border-none">
      <div className="flex flex-col bg-bg w-[420px]">
        <div className="p-2 bg-bg-3 text-h6 text-orange rounded-t-[5px]">Maximum Team Member Limit Reached</div>
        <div className="flex flex-col gap-3 w-full rounded-md p-3">
          <span className="text-bodySm text-white">You have reached your maximum team member limit. If you want to invite more member, you can upgrade your plan.</span>
        </div>
        <div className="flex bg-bg-2 gap-2.5 p-2.5 border border-bg-3 rounded-b-[5px]">
          <Button className="btn-secondary w-full" onClick={onClose}>
            Cancel
          </Button>
          <Button className="btn-primary w-full" onClick={() => navigate("/pricing")}>
            Upgrade Plan
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const MultipleActionBar = ({ count, handleRemoveMembers, handleEditMemberRoles }: { count: number; handleRemoveMembers: () => void; handleEditMemberRoles: (selectedItem: any) => void }) => (
  <div className="fixed bottom-10 right-1/2 left-1/2 flex gap-5 w-fit border border-bg-3 bg-bg rounded-md p-2.5 text-white">
    <span className="text-bodySm text-grey whitespace-nowrap">{count} Users Selected:</span>
    <Select
      key={`MultipleActionBar_Select`}
      bodyClassName="gap-0 !text-green underline"
      menuContainerClassName="!w-[100px] !bottom-full !mb-6 !top-auto"
      values={["Admin", "Editor", "Viewer"]}
      defaultValue={"Viewer"}
      selectCallback={handleEditMemberRoles}
    />
    <span className="cursor-pointer flex text-bodySm text-orange  underline" onClick={handleRemoveMembers}>
      Remove <IconTrash className="w-[18px] h-[18px]" />
    </span>
  </div>
);

const TripleDotMenu = ({ item, handleRemoveMember }: any) => {
  //TODO snackbar dispatch lazim

  const menuRef = useRef<HTMLDivElement>(null);
  const [show, setShow] = useState(false);
  useClickOutside(menuRef, () => {
    setShow(false);
  });

  return (
    <div className="relative">
      <IconMore className={clsx("cursor-pointer hover:text-white", show ? "text-white pointer-events-none" : "text-grey")} onClick={() => setShow((prev) => !prev)} />
      {show && (
        <div ref={menuRef} className="cursor-pointer absolute right-0 w-[200px] rounded-[5px] p-4 bg-bg">
          <span className="flex items-center gap-2.5 text-bodyMd text-grey" onClick={() => handleRemoveMember(item.id)}>
            <IconTrash className="w-[18px] h-[18px]" />
            Remove Member
          </span>
        </div>
      )}
    </div>
  );
};

const ManageMembers = () => {
  const dispatch = useDispatch();
  const [showOutOfCredit, setShowOutOfCredit] = useState(false);
  const [showAddUser, setShowAddUser] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<any>([]);
  const [users, setUsers] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const { user } = useAppSelector((state) => state.common);

  // const dispatch = useDispatch();
  // dispatch(
  //   setSnackbar({
  //     icon: "",
  //     message: "Role(s) is changed",
  //   })
  // );
  // dispatch(
  //   setSnackbar({
  //     icon: "",
  //     message: "Team member(s) is removed",
  //   })
  // );

  const filteredUsers = users.filter((user: any) => {
    const isInSearch = user.name.includes(searchTerm) || user.email.includes(searchTerm);
    const isSelected = selectedUsers.some((selected: any) => selected.id === user.id);

    return isInSearch || isSelected;
  });

  React.useEffect(() => {
    fetchTeamMembers();
  }, []);

  const fetchTeamMembers = async () => {
    try {
      const res = await teamsService.getTeamMembers();
      setUsers(res.responseData);
    } catch (error) {
      console.error("Failed to fetch team members", error);
    }
  };

  async function handleInviteUsers(emailArray: string[], role: string) {
    const members = emailArray.map((email) => ({ email: email, role: role }));

    try {
      await teamsService.sendInvite({ members });
      setShowAddUser(false);
      dispatch(setSnackbar({ icon: IconCheck, message: "Invite(s) are sent." }));
    } catch (error) {
      dispatch(setSnackbar({ icon: IconWarning, message: "Something went wrong. Please try again." }));
    }
  }

  async function handleRemoveMember(id: any) {
    try {
      const res = await teamsService.deleteMember(id);
      console.log(res);
      setUsers((prev: any) => prev.filter((user: any) => user.id !== id));
    } catch (error) {
      console.log(error);
    }
  }

  async function handleEditMemberRole(member_id: any, selectedValue: any) {
    const res = await teamsService.editMemberRole(member_id, {
      uid: member_id,
      role: selectedValue,
    });
    console.log(res);
  }

  async function handleEditMemberRoles(selectedValue: any) {
    try {
      const items = selectedUsers.map((item: any) => ({ uid: item.id, role: selectedValue }));

      await teamsService.editTeamMembersRoles(items);
      const _selectedIds = items.flatMap((item: any) => item.uid);

      const updatedUsers = users.map((user: any) => {
        if (_selectedIds.includes(user.id)) return { ...user, role: selectedValue };
        else return user;
      });

      setUsers(updatedUsers);
    } catch (error) {
      console.log(error);
    }
  }

  async function handleRemoveMembers() {
    const ids = selectedUsers.map((item: any) => ({ uid: item.id }));

    try {
      const res = await teamsService.deleteTeamMembers(ids);
      console.log(res);
      const _selectedIds = ids.flatMap((item: any) => item.uid);
      setUsers((prev: any) => prev.filter((user: any) => !_selectedIds.includes(user.id)));
    } catch (error) {
      console.log(error);
    }
  }

  const handleSearch = (input: string) => {
    setSearchTerm(input);
  };

  const isSelected = (id: any) => selectedUsers.some((user: any) => user.id === id);

  const headers: ITableHeader[] = [
    {
      key: "selection",
      width: "fit-content",
      text: (
        <Checkbox
          onChange={(_: any, isSelected: boolean) => {
            if (isSelected) setSelectedUsers([]);
            else setSelectedUsers(filteredUsers);
          }}
        />
      ),
      render: (item) => {
        return (
          <Checkbox
            checked={isSelected(item.id)}
            onChange={(_: any, isSelected: boolean) => {
              if (isSelected) setSelectedUsers((prev: any) => prev.filter((_item: any) => _item.id !== item.id));
              else setSelectedUsers((prev: any) => [...prev, item]);
            }}
          />
        );
      },
    },
    {
      key: "user",
      text: "User",
      render: (item) => (
        <div className="flex items-center gap-2.5">
          <div className="flex-center w-8 h-8 bg-bg-3 rounded-full text-h6">T</div>
          <div className="flex flex-col gap-[2px]">
            <span className="text-h7 text-white">{item.name}</span>
            <span className="text-bodySm text-grey">{item.email}</span>
          </div>
        </div>
      ),
    },
    {
      key: "role",
      text: "Role",
      render: (item) => (
        <Select
          item={item}
          key={`ManageMembers_Select_${item?.id}`}
          bodyClassName="gap-0 !text-green underline !w-[100px]"
          menuContainerClassName="!w-[100px]"
          values={["Admin", "Editor", "Viewer"]}
          defaultValue={item?.role ?? "-"}
          selectCallback={(selectedValue: any) => handleEditMemberRole(item.id, selectedValue)}
        />
      ),
    },
    {
      key: "triple-dot",
      text: "",
      width: "24px",
      align: "flex-end",
      render: (item) => <TripleDotMenu item={item} handleRemoveMember={handleRemoveMember} />,
    },
  ];

  return (
    <div className="flex flex-col gap-5">
      <div className="flex items-center justify-between w-full">
        <Button className="btn-primary-small" onClick={() => setShowAddUser(true)}>
          <IconPlus className="w-[18px] h-[18px]" /> Add User
        </Button>
        <div>
          <Search onSearch={handleSearch} />
        </div>
      </div>
      <Table headers={headers} items={filteredUsers} isSelectedRow={(item) => item.isSelected} />
      <OutOfCredit show={showOutOfCredit} onClose={() => setShowOutOfCredit(false)} />
      <AddUser show={showAddUser} onClose={() => setShowAddUser(false)} handleInviteUsers={handleInviteUsers} />
      {selectedUsers.length > 0 && <MultipleActionBar count={selectedUsers.length} handleRemoveMembers={handleRemoveMembers} handleEditMemberRoles={handleEditMemberRoles} />}
    </div>
  );
};

export default ManageMembers;
