import clsx from "clsx";
import Button from "components/Button";
import Input from "components/Input";
import React, { useEffect, useState } from "react";
import DeleteAccountModal from "./DeleteAccountModal";
import { useAppSelector } from "store";
import userService from "api/user/user.service";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { IconEyeOff, IconEyeOn, IconWarning } from "icons";
import { useDispatch } from "react-redux";
import { setSnackbar } from "store/commonSlice";
import InputUpload from "components/InputUpload";

const passwordSchema = yup
  .object({
    currentPassword: yup.string().required("Please enter current password!"),
    newPassword: yup.string().required("Please enter a password!"),
    confirmNewPassword: yup
      .string()
      .required("Please enter a password!")
      .test("passwords-match", "Passwords must match", function (value) {
        return this.parent.newPassword === value;
      }),
  })
  .required();

const userDetailSchema = yup
  .object({
    name: yup.string(),
    work: yup.string(),
  })
  .required();

const AccountSettings = () => {
  const { user } = useAppSelector((state) => state.common);
  const dispatch = useDispatch();

  const [changePasswordOpen, setChangePasswordOpen] = useState(false);
  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [uploadedPhoto, setUploadedPhoto] = useState("");

  const {
    register: registerPassword,
    handleSubmit: handleSubmitPassword,
    formState: { errors: passwordErrors },
  } = useForm({
    resolver: yupResolver(passwordSchema),
  });

  const {
    register: reqisterUserDetail,
    handleSubmit: handleSubmitUserDetail,
    // formState: { errors: userDetailErrors },
    setValue,
  } = useForm({
    resolver: yupResolver(userDetailSchema),
    defaultValues: { name: user.name ?? "" },
  });

  useEffect(() => {
    setValue("name", user.name);
  }, [user]);

  const onPasswordChangeValid = (data: any) => {
    const currentPassword = data.currentPassword;
    const newPassoword = data.newPassword;
    const newPasswordConfirm = data.confirmNewPassword;

    if (user.email && user.id) {
      userService
        .changePassword(user.id, {
          email: user.email,
          current_password: currentPassword,
          password: newPassoword,
          password_replay: newPasswordConfirm,
        })
        .then(() => {
          dispatch(
            setSnackbar({
              icon: "",
              message: "Your password successfully changed.",
            })
          );
        })
        .catch(() => {
          dispatch(
            setSnackbar({
              icon: IconWarning,
              message: "Something went wrong.",
            })
          );
        });
    }
  };
  const onChangePasswordSubmit = () => {
    handleSubmitPassword(onPasswordChangeValid, (e) => console.log(e))();
  };

  const onEditUserDetailValid = (data: any) => {
    const name = data.name ?? "";
    const newProfilePhoto = uploadedPhoto;

    //NO CHANGE
    if (name === user.name && newProfilePhoto === user.profilePhoto) return;

    userService
      .editUserDetail(user.id, { name: name, profile_photo: newProfilePhoto })
      .then(() => {
        dispatch(
          setSnackbar({
            icon: "",
            message: "Account detials updated successfully.",
          })
        );
      })
      .catch(() => {
        dispatch(
          setSnackbar({
            icon: IconWarning,
            message: "Something went wrong.",
          })
        );
      });
  };
  const onEditUserDetailSubmit = () => {
    handleSubmitUserDetail(onEditUserDetailValid, (e) => console.log(e))();
  };

  return (
    <div className="flex flex-col gap-2.5">
      <div className="flex flex-col rounded-md bg-bg-3 border border-bg-3 max-w-[386px]">
        <div className="p-2">
          <h6 className="text-h6 text-white">Profile</h6>
        </div>
        <div className="flex flex-col p-2.5 gap-5 bg-bg">
          <div className="flex items-center gap-2.5">
            <div className="flex-center rounded-full bg-bg-3 w-16 h-16 text-h3 text-white uppercase overflow-hidden">
              {uploadedPhoto || user.profilePhoto ? <img src={uploadedPhoto ? uploadedPhoto : user.profilePhoto} /> : user?.name[0]}
            </div>
            <InputUpload callback={(url: string) => setUploadedPhoto(url)}>
              <Button className="btn-primary h-9 px-2">Upload Picture</Button>
            </InputUpload>
          </div>
          <div className="flex flex-col gap-3">
            <div className="flex flex-col gap-2.5">
              <span className="text-h7 text-white">Email</span>
              <Input disabled placeholder="e.g: dress, skirt, .etc." defaultValue={user?.email} />
            </div>
            <div className="flex flex-col gap-2.5">
              <span className="text-h7 text-white">Full Name</span>
              <Input placeholder="e.g: dress, skirt, .etc." {...reqisterUserDetail("name")} defaultValue={user?.name} />
            </div>
          </div>
        </div>
        <div className="flex justify-end gap-2.5 p-2.5 bg-bg-2 rounded-b-md">
          <Button className="btn-primary h-9 px-2" onClick={onEditUserDetailSubmit}>
            Save Changes
          </Button>
        </div>
      </div>
      <div className="flex flex-col rounded-md bg-bg-3 border border-bg-3 max-w-[386px]">
        <div className="p-2 cursor-pointer" onClick={() => setChangePasswordOpen((prev) => !prev)}>
          <h6 className={clsx("text-h6 w-fit", !changePasswordOpen ? "text-green underline" : "text-white")}>Change Password</h6>
        </div>
        {changePasswordOpen && (
          <div className={clsx("flex flex-col gap-5 bg-bg rounded-b-md")}>
            <div className="flex flex-col p-2.5">
              <div className="flex flex-col gap-3">
                <div className="flex flex-col gap-2.5">
                  <span className="text-h7 text-white">Current Password</span>
                  <Input
                    type={showPassword ? "text" : "password"}
                    placeholder="Set New Password"
                    error={passwordErrors.currentPassword?.message}
                    {...registerPassword("currentPassword")}
                    icon={
                      !showPassword ? (
                        <IconEyeOn className="text-grey hover:text-white cursor-pointer" onClick={() => setShowPassword(true)} />
                      ) : (
                        <IconEyeOff className="text-white hover:text-grey cursor-pointer" onClick={() => setShowPassword(false)} />
                      )
                    }
                  />
                </div>
                <div className="flex flex-col gap-2.5">
                  <span className="text-h7 text-white">New Password</span>
                  <Input
                    type={showNewPassword ? "text" : "password"}
                    placeholder="Confirm New Password"
                    error={passwordErrors.newPassword?.message}
                    {...registerPassword("newPassword")}
                    icon={
                      !showNewPassword ? (
                        <IconEyeOn className="text-grey hover:text-white cursor-pointer" onClick={() => setShowNewPassword(true)} />
                      ) : (
                        <IconEyeOff className="text-white hover:text-grey cursor-pointer" onClick={() => setShowNewPassword(false)} />
                      )
                    }
                  />
                </div>
                <div className="flex flex-col gap-2.5">
                  <span className="text-h7 text-white">Confirm New Password</span>
                  <Input
                    type={showConfirmNewPassword ? "text" : "password"}
                    placeholder="Confirm New Password"
                    error={passwordErrors.confirmNewPassword?.message}
                    {...registerPassword("confirmNewPassword")}
                    icon={
                      !showConfirmNewPassword ? (
                        <IconEyeOn className="text-grey hover:text-white cursor-pointer" onClick={() => setShowConfirmNewPassword(true)} />
                      ) : (
                        <IconEyeOff className="text-white hover:text-grey cursor-pointer" onClick={() => setShowConfirmNewPassword(false)} />
                      )
                    }
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-end gap-2.5 p-2.5 bg-bg-2 rounded-b-md">
              <Button className="btn-primary h-9 px-2" onClick={onChangePasswordSubmit}>
                Change Password
              </Button>
            </div>
          </div>
        )}
      </div>
      {/* <div className="flex flex-col rounded-md bg-bg-3 border border-bg-3 max-w-[386px] p-2">
        <h6 className="cursor-pointer text-h6 text-orange underline w-fit" onClick={() => setShowDeleteAccountModal(true)}>
          Delete Account
        </h6>
      </div>
      <DeleteAccountModal show={showDeleteAccountModal} onClose={() => setShowDeleteAccountModal(false)} /> */}
    </div>
  );
};

export default AccountSettings;
