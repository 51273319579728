import React from "react";
import clsx from "clsx";
import { TabProvider } from "./context/TabContext";
import { useTabContext } from "./hooks/useTabContext";

export interface TabProps {
  className?: string;
  children: React.ReactNode;
  initTab: any;
  onChange?: (tabId: any) => void;
}

export interface TabItemProps {
  children: React.ReactNode;
  className?: string;
  id: any;
  disabled?: boolean;
  disabledClassName?: string;
}

const Item = (props: TabItemProps) => {
  const { changeActiveTab, activeTab } = useTabContext();
  const isActive = JSON.stringify(activeTab) === JSON.stringify(props.id);
  const onClick = () => {
    if (props.disabled) {
      return false;
    }
    changeActiveTab(props.id);
  };

  return (
    <li onClick={onClick} className={clsx(props.className, props.disabled ? "pointer-events-none" : "")}>
      <div className="flex-center">
        <span className={clsx(isActive ? "active" : "", props.disabled ? props.disabledClassName : "")}>{props.children}</span>
      </div>
    </li>
  );
};

const TabRoot = (props: TabProps) => {
  return (
    <TabProvider {...props}>
      <ul className={clsx("tab", props.className)}>{props.children}</ul>
    </TabProvider>
  );
};

const Button = ({ className, value, onClick }: { className?: string; value: any; onClick: () => void }) => {
  return (
    <button className={clsx("tab-button", className)} onClick={onClick}>
      {value}
    </button>
  );
};

const Tab = Object.assign(TabRoot, { Item, Button });

export default Tab;
