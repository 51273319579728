import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { useClickOutside } from "hooks/useClickOutside";
import { IconChevronDown } from "icons";
import { useDispatch } from "react-redux";

const Select = ({ values, bodyClassName, menuContainerClassName, containerClassName, defaultValue, selectCallback }: any) => {
  const dispatch = useDispatch();
  const [selectedValue, setSelectedValue] = useState(defaultValue ? defaultValue : values.length > 0 ? values[0] : null);
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (defaultValue === values[0]) return;
    setSelectedValue(defaultValue);
  }, [defaultValue]);

  function handleSelect(item: any) {
    setSelectedValue(item);
    if (selectCallback) selectCallback(item);
    setShowDropdown(false);
    // if (typeof onSelect === "function") onSelect(item);
    // setSelected(item);
    // if (snackbarMessage !== null) dispatch(setSnackbar({ icon: IconCheck, message: snackbarMessage }));
  }

  useClickOutside(dropdownRef, () => {
    setShowDropdown(false);
  });

  return (
    <div className={clsx("relative", containerClassName)}>
      <span className={clsx("flex gap-2.5 text-bodySm text-white", bodyClassName, showDropdown ? "pointer-events-none" : "cursor-pointer")} onClick={() => setShowDropdown((prev) => !prev)}>
        <span>{selectedValue}</span>
        <IconChevronDown className="w-[18px] h-[18px]" />
      </span>
      {showDropdown && (
        <div ref={dropdownRef} className={clsx("absolute w-full z-50 top-8 right-0 flex flex-col gap-[5px] rounded-lg bg-bg-2 border border-bg-3 px-2.5 py-3", menuContainerClassName)}>
          {values.map((item: any, index: number) => (
            <div
              key={`item_${index}`}
              onClick={() => handleSelect(item)}
              className={clsx(
                "cursor-pointer text-bodySm px-2.5 py-3 rounded-[3px] border",
                selectedValue === item ? "text-green bg-green bg-opacity-10 border-transparent" : "text-white border-bg-3"
              )}
            >
              {item}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Select;
