import React from "react";
import { PATHS } from "./paths";
import Layout from "pages/Layout/Layout";

const PhotoshootPage = React.lazy(() => import("pages/PhotoshootPage"));

const DesignPage = React.lazy(() => import("pages/DesignPage"));
const TrainingsPage = React.lazy(() => import("pages/TrainingsPage"));
const TrendsPage = React.lazy(() => import("pages/TrendsPage"));
const NewTrainingPage = React.lazy(() => import("pages/NewTrainingPage"));
const Home = React.lazy(() => import("pages/Home"));
const Login = React.lazy(() => import("pages/Login"));
const SignUp = React.lazy(() => import("pages/SignUp"));

const CollectionsPage = React.lazy(() => import("pages/CollectionsPage"));
const DesignHistoryPage = React.lazy(() => import("pages/DesignHistoryPage"));
const DiscoverPage = React.lazy(() => import("pages/DiscoverPage"));
const PhotoshootsPage = React.lazy(() => import("pages/PhotoshootsPage"));
const TechPacksPage = React.lazy(() => import("pages/TechPacksPage"));

const Account = React.lazy(() => import("pages/Account"));
const SharedPage = React.lazy(() => import("pages/SharedPage"));
const Explore = React.lazy(() => import("pages/Explore"));
const ExploreNews = React.lazy(() => import("pages/Explore/News"));
const ExploreTutorials = React.lazy(() => import("pages/Explore/Tutorials"));
const NewPassword = React.lazy(() => import("pages/NewPassword"));
const EmailVerified = React.lazy(() => import("pages/EmailVerified"));
const PricingPage = React.lazy(() => import("pages/PricingPage"));
const PrivacyPolicy = React.lazy(() => import("pages/PrivacyPolicy"));
const TermsOfUse = React.lazy(() => import("pages/TermsOfUse"));
const AcceptInvite = React.lazy(() => import("pages/AcceptInvite"));
const Test = React.lazy(() => import("pages/Test"));

export interface RouteConfig {
  path: string;
  component: React.LazyExoticComponent<() => JSX.Element>;
  layout?: React.ElementType;
  layoutProps?: {
    hiddenFooter?: boolean;
    hiddenHeader?: boolean;
    isSideMenuFixed?: boolean;
  };
  needLogin?: boolean;
  requireLogin?: boolean;
  children?: RouteConfig[];
  isResponsive?: boolean;
  paidPlanNeeded?: boolean;
}

export const ROUTES: RouteConfig[] = [
  {
    path: PATHS.TEST,
    component: Test,
    layout: Layout,
    requireLogin: false,
    paidPlanNeeded: false,
    layoutProps: { isSideMenuFixed: false, hiddenHeader: true },
    isResponsive: true,
  },
  { path: PATHS.LOGIN, component: Login, layout: Layout, requireLogin: false, paidPlanNeeded: false, layoutProps: { isSideMenuFixed: false, hiddenHeader: true }, isResponsive: true },
  { path: PATHS.SIGNUP, component: SignUp, layout: Layout, requireLogin: false, paidPlanNeeded: false, layoutProps: { isSideMenuFixed: false, hiddenHeader: true }, isResponsive: true },

  { path: PATHS.PHOTOSHOOT, component: PhotoshootPage, layout: Layout, requireLogin: true, paidPlanNeeded: true, layoutProps: { hiddenHeader: true, isSideMenuFixed: false }, isResponsive: false },
  {
    path: PATHS.TECH_PACKS,
    component: TechPacksPage,
    layout: Layout,
    requireLogin: true,
    paidPlanNeeded: true,
    layoutProps: { hiddenHeader: true, isSideMenuFixed: false },
    isResponsive: false,
  },

  { path: PATHS.HOME, component: Home, layout: Layout, requireLogin: true, paidPlanNeeded: false, layoutProps: { isSideMenuFixed: true }, isResponsive: false },
  { path: PATHS.COLLECTIONS, component: CollectionsPage, layout: Layout, requireLogin: true, paidPlanNeeded: true, layoutProps: { isSideMenuFixed: true }, isResponsive: false },
  { path: PATHS.TRAININGS, component: TrainingsPage, layout: Layout, requireLogin: true, paidPlanNeeded: true, layoutProps: { isSideMenuFixed: true }, isResponsive: false },
  { path: PATHS.TRENDS, component: TrendsPage, layout: Layout, requireLogin: true, paidPlanNeeded: true, layoutProps: { isSideMenuFixed: true }, isResponsive: false },
  { path: PATHS.DESIGN_HISTORY, component: DesignHistoryPage, layout: Layout, requireLogin: true, paidPlanNeeded: true, layoutProps: { isSideMenuFixed: true }, isResponsive: false },
  { path: PATHS.DISCOVER, component: DiscoverPage, layout: Layout, requireLogin: true, paidPlanNeeded: true, layoutProps: { isSideMenuFixed: true }, isResponsive: false },
  { path: PATHS.PHOTOSHOOTS, component: PhotoshootsPage, layout: Layout, requireLogin: true, paidPlanNeeded: true, layoutProps: { isSideMenuFixed: true }, isResponsive: false },
  { path: PATHS.PAYMENT_SUCCESS, component: PricingPage, layout: Layout, requireLogin: false, paidPlanNeeded: false, layoutProps: { hiddenHeader: true, isSideMenuFixed: false }, isResponsive: true },
  { path: PATHS.PAYMENT_FAIL, component: PricingPage, layout: Layout, requireLogin: false, paidPlanNeeded: false, layoutProps: { hiddenHeader: true, isSideMenuFixed: false }, isResponsive: true },
  { path: PATHS.PRICING, component: PricingPage, layout: Layout, requireLogin: false, paidPlanNeeded: false, layoutProps: { hiddenHeader: true, isSideMenuFixed: false }, isResponsive: true },
  { path: PATHS.ACCEPT_INVITE, component: AcceptInvite, requireLogin: false, layout: Layout, paidPlanNeeded: false, layoutProps: { hiddenHeader: true, isSideMenuFixed: false }, isResponsive: true },

  { path: PATHS.ACCOUNT, component: Account, layout: Layout, requireLogin: true, paidPlanNeeded: false, layoutProps: { isSideMenuFixed: true, hiddenHeader: false }, isResponsive: false },
  { path: PATHS.DESIGN, component: DesignPage, layout: Layout, requireLogin: true, paidPlanNeeded: true, layoutProps: { hiddenHeader: true, isSideMenuFixed: false }, isResponsive: false },
  { path: PATHS.NEW_TRAINING, component: NewTrainingPage, layout: Layout, requireLogin: true, paidPlanNeeded: true, layoutProps: { hiddenHeader: true, isSideMenuFixed: false }, isResponsive: false },
  { path: PATHS.RESET_PASSWORD, component: NewPassword, layout: Layout, requireLogin: false, paidPlanNeeded: false, layoutProps: { hiddenHeader: true, isSideMenuFixed: false }, isResponsive: true },
  {
    path: PATHS.EMAIL_VERIFY,
    component: EmailVerified,
    layout: Layout,
    requireLogin: false,
    paidPlanNeeded: false,
    layoutProps: { hiddenHeader: true, isSideMenuFixed: false },
    isResponsive: true,
  },
  {
    path: PATHS.PRIVACY_POLICY,
    component: PrivacyPolicy,
    layout: Layout,
    requireLogin: false,
    paidPlanNeeded: false,
    layoutProps: { hiddenHeader: true, isSideMenuFixed: false },
    isResponsive: false,
  },
  {
    path: PATHS.TERMS_OF_USE,
    component: TermsOfUse,
    layout: Layout,
    requireLogin: false,
    paidPlanNeeded: false,
    layoutProps: { hiddenHeader: true, isSideMenuFixed: false },
    isResponsive: false,
  },
  { path: PATHS.ACCOUNT, component: Account, layout: Layout, requireLogin: true, paidPlanNeeded: true, layoutProps: { isSideMenuFixed: true }, isResponsive: false },
  { path: PATHS.Shared, component: SharedPage, layout: Layout, requireLogin: true, paidPlanNeeded: true, layoutProps: { isSideMenuFixed: true }, isResponsive: false },
  { path: PATHS.EXPLORE, component: Explore, layout: Layout, requireLogin: true, paidPlanNeeded: true, layoutProps: { isSideMenuFixed: true }, isResponsive: false },
  { path: PATHS.EXPLORE_NEWS, component: ExploreNews, layout: Layout, requireLogin: true, paidPlanNeeded: true, layoutProps: { isSideMenuFixed: true }, isResponsive: false },
  { path: PATHS.EXPLORE_TUTORIALS, component: ExploreTutorials, layout: Layout, requireLogin: true, paidPlanNeeded: true, layoutProps: { isSideMenuFixed: true }, isResponsive: false },
];
