import { createSlice } from "@reduxjs/toolkit";

export const snackbarSlice = createSlice({
  name: "snackbar",
  initialState: {
    visible: false,
    type: "" as any,
    message: "",
    screen: 0,
  },
  reducers: {
    showSnackbar: (state, payload) => {
      state.visible = true;
      state.type = payload.payload.type;
      state.message = payload.payload.message;
      state.screen = payload.payload.screen;
    },
    hideSnackbar: (state) => {
      state.visible = false;
    },
  },
  extraReducers: {},
});

export const { showSnackbar, hideSnackbar } = snackbarSlice.actions;

export default snackbarSlice.reducer;
