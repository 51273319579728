import clsx from "clsx";
import Button from "components/Button";
import { useClickOutside } from "hooks/useClickOutside";
import { useIsMobile } from "hooks/useIsMobile";
import { IconCamera, IconDesign, IconHelp, IconPlus, IconSettings2, IconTechPacks, IconTrain } from "icons";
import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "store";
import { AnalyticsEvent } from "utils";
import HelpCenter from "./HelpCenter";

const CommonHeader = ({ isFixed }: any) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const menuRef = useRef<HTMLDivElement>(null);
  const { isMainMenuFixed, isAuthorized } = useAppSelector((state) => state.common);

  const [showMenu, setShowMenu] = React.useState(false);
  const [showHelp, setShowHelp] = React.useState(false);

  useClickOutside(menuRef, () => {
    setShowMenu(false);
  });

  const menuItems = [
    {
      title: "New Design",
      icon: <IconDesign className="w-[18px] h-[18px] flex-shrink-0" />,
      onClick: () => {
        AnalyticsEvent("Start_New", "", "click", { panel: "design" });
        navigate("/design");
      },
    },
    {
      title: "New Training",
      icon: <IconTrain className="w-[18px] h-[18px] flex-shrink-0" />,
      onClick: () => {
        AnalyticsEvent("Start_New", "", "click", { panel: "training" });
        navigate("/new-training");
      },
    },
    {
      title: "New Photoshoot",
      icon: <IconCamera className="w-[18px] h-[18px] flex-shrink-0" />,
      onClick: () => {
        AnalyticsEvent("Start_New", "", "click", { panel: "photoshoot" });
        navigate("/photoshoot");
      },
    },
    {
      title: "New Tech Pack",
      icon: <IconTechPacks className="w-[18px] h-[18px] flex-shrink-0" />,
      disabled: true,
      soon: true,
      onClick: () => {
        AnalyticsEvent("Start_New", "", "click", { panel: "photoshoot" });
        navigate("/photoshoot");
      },
    },
  ];

  const StartNewMenu = () => {
    return (
      <div className="flex flex-col w-[240px] p-1 border border-bg-3 bg-bg rounded-[5px]" ref={menuRef}>
        {menuItems.map((item, index) => (
          <div className="flex w-full items-center justify-between" key={index}>
            <button
              disabled={item.disabled}
              className="flex items-center w-full gap-2.5 text-bodyMd text-white hover:bg-green hover:bg-opacity-10 hover:text-green rounded p-2 disabled:pointer-events-none disabled:text-grey"
              onClick={item.onClick}
            >
              {item.icon}
              {item.title}
            </button>
            {item.soon && <span className="pointer-events-none h-fit text-center bg-bg-3 rounded-[3px] text-green text-headline py-[3px] px-[5px] uppercase">soon</span>}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="flex w-full justify-between px-5">
      <div className={clsx("flex w-full transition-all duration-500", isFixed || isMainMenuFixed ? "" : "")}></div>

      <div className="flex items-center gap-4">
        <div className="relative flex">
          <Button
            className="btn-primary-small w-[144px] xs:!w-fit !bg-green hover:!bg-purple"
            disabled={!isAuthorized}
            onClick={() => {
              AnalyticsEvent("header_start_new", "", "click");
              setShowMenu(true);
            }}
          >
            {isMobile ? (
              <IconDesign className="w-[18px] h-[18px]" />
            ) : (
              <>
                <IconPlus className="w-5 h-5" />
                Start New
              </>
            )}
          </Button>
          {showMenu && (
            <div className="absolute top-11 right-0 bg-bg-2 z-[9990]">
              <StartNewMenu />
            </div>
          )}
        </div>
        <div className="h-5 w-[1px] bg-bg-3" />
        <IconHelp
          className="cursor-pointer text-grey hover:text-white"
          onClick={() => {
            setShowHelp(!showHelp);
          }}
        />
        <HelpCenter
          show={showHelp}
          onClose={() => {
            setShowHelp(false);
          }}
        />
      </div>
    </div>
  );
};

export default CommonHeader;
