import React from "react";
import CopyButton from "components/CopyButton";
import { IconBrush, IconCopy, IconDesign, IconLasso, IconMagicSelection, IconModel, IconMood, IconRemoveBg, IconStyle, IconTip } from "icons";
import { ImageSketchExample } from "images";
import Tab from "components/Tab";
import { useDispatch } from "react-redux";
import { setIsSketchAdded } from "store/commonSlice";

////// DESIGN

const TabContent = () => {
  const [currentIndex, setCurrentIndex] = React.useState(0);

  const prompts = [
    "A strapless black jumpsuit features a structured, fitted bodice with a peplum waist accentuated by a small bow. The slim, tailored trousers give an elongated silhouette. Design details include black piping along the bodice edges and long peplum, with matching pearl necklace and bracelet, adding elegance.",
    "Brunette fashion model",
    "Studio photoshoot",
  ];

  return (
    <div className="flex flex-col">
      <div className="flex items-center gap-[5px] text-headline uppercase text-white bg-bg-3 p-2 rounded-t-[5px]">
        <IconTip className="w-[14px] h-[14px]" /> TRY THIS PROMPT
      </div>
      <div className="flex flex-col gap-3 rounded-b-[5px] p-3 border border-bg-3 bg-bg">
        <div className="flex w-full gap-[5px]">
          <Tab
            initTab={0}
            onChange={(value: any) => {
              setCurrentIndex(value);
            }}
          >
            <Tab.Item id={0}>
              <div className="flex-center gap-[5px] w-full">
                <IconDesign className="w-[18px] h-[18px]" />
                Define Design
              </div>
            </Tab.Item>
            <Tab.Item id={1}>
              <div className="flex-center gap-[5px] w-full">
                <IconModel className="w-[18px] h-[18px]" />
                Model
              </div>
            </Tab.Item>
            <Tab.Item id={2}>
              <div className="flex-center gap-[5px] w-full">
                <IconRemoveBg className="w-[18px] h-[18px]" />
                Background
              </div>
            </Tab.Item>
          </Tab>
        </div>
        <span className="text-bodySm text-white">{prompts[currentIndex]}</span>
        <CopyButton copyData={prompts[currentIndex]} className="self-end h-[18px] w-fit text-h7 text-green hover:text-white">
          <IconCopy className="w-4 h-4" /> Copy Prompt
        </CopyButton>
      </div>
    </div>
  );
};

const AddThisSketch = () => {
  const dispatch = useDispatch();

  function onAdd() {
    dispatch(setIsSketchAdded(true));
  }

  return (
    <div className="flex flex-col w-full">
      <div className="flex gap-[5px] text-headline text-white uppercase bg-bg-3 p-2 rounded-t-[5px]">
        <IconTip className="w-[14px] h-[14px]" /> TRY THIS SKETCH
      </div>
      <div className="flex flex-col p-3 gap-3 border-b border-x border-bg-3 rounded-b-[5px]">
        <img src={ImageSketchExample} className="rounded-[3px] h-[90px] w-[90px]" />
        <div className="flex justify-end w-full">
          <button className="text-h7 text-green" onClick={onAdd}>
            + Add This Sketch
          </button>
        </div>
      </div>
    </div>
  );
};

export const DesignTutorialData: any = [
  {
    image: "Dummy",
    header: "Dummy",
    description: "Dummy",
  },
  {
    image: "https://s3.amazonaws.com/cdn.refabric.com/static/onboarding/Design/Design-Step-1.mp4",
    header: "Click on New Design",
    description: () => (
      <span>
        To create your first design, click on<span className="text-green"> + Design</span>
      </span>
    ),
  },
  {
    image: "https://s3.amazonaws.com/cdn.refabric.com/static/onboarding/Design/Design-Step-2.mp4",

    header: "Select Design Style",
    description: "",
  },
  {
    image: "https://s3.amazonaws.com/cdn.refabric.com/static/onboarding/Design/Design-Step-3.mp4",
    header: "Choose a Design Training",
    description: () => (
      <span>
        You can choose from existing training models or create a new one. You can choose multiple training models at once. These design trainings will help guide your creations. Now select{" "}
        <span className="text-green">Fashion World </span>and click <span className="text-green">Save</span>.
      </span>
    ),
  },
  {
    image: "https://s3.amazonaws.com/cdn.refabric.com/static/onboarding/Design/Design-Step-4.mp4",
    header: "Define your Design",
    description: "Describe your Design, Model, and Background separately. Inside each prompt section, write a clear description of what you intend to create.",
    content: () => {
      return <TabContent />;
    },
  },
  {
    image: "https://s3.amazonaws.com/cdn.refabric.com/static/onboarding/Design/Design-Step-5.mp4",
    header: "Click on Sketch & Pose",
    description: "You can upload a Sketch to clarify your prompt. You can also add a specific Pose Image. These references will guide your creations.",
    content: () => <AddThisSketch />,
  },
  {
    image: "https://s3.amazonaws.com/cdn.refabric.com/static/onboarding/Design/Design-Step-6.mp4",
    header: "Click on Create",
    description: "You are almost done! Now let's see the magic of Refabric.",
  },
];

////// TRAIN

export const TrainTutorialData: any = [
  {
    image: "Dummy",
    header: "Dummy",
    description: "Dummy",
  },
  {
    image: "https://s3.amazonaws.com/cdn.refabric.com/static/onboarding/Train/Train-Step-1.mp4",
    header: "Click on New Training",
    description: () => (
      <span>
        To train your first AI model, click on<span className="text-green"> + Train</span>
      </span>
    ),
  },
  {
    image: "https://s3.amazonaws.com/cdn.refabric.com/static/onboarding/Train/Train-Step-2.mp4",
    header: "Choose a Training Type",
    description: () => (
      <span>
        You can either choose Train Look or Train Mood to develop your Training Type. Click on <span className="text-green">Training Look</span>.
      </span>
    ),
    content: () => (
      <div className="flex flex-col w-full">
        <div className="flex gap-[5px] text-headline text-white uppercase bg-bg-3 p-2 rounded-t-[5px]">
          <IconTip className="w-[14px] h-[14px]" /> TRANING MODELS
        </div>
        <div className="flex flex-col p-3 gap-3 border-b border-x border-bg-3 rounded-b-[5px]">
          <div className="flex gap-2.5 items-center">
            <IconStyle className="text-green flex-shrink-0 w-4 h-4" />
            <span className="text-h7 text-white">
              <b>Look Training:</b> Fine-tune AI to craft and recognize fashion styles matching brand identities and prevailing trends.
            </span>
          </div>
          <div className="flex gap-2.5 items-center">
            <IconMood className="text-orange flex-shrink-0 w-4 h-4" />
            <span className="text-h7 text-white">
              <b>Mood Training:</b> Train AI to derive fashion moods from diverse inputs like structures, objects, and color schemes.
            </span>
          </div>
        </div>
      </div>
    ),
  },
  {
    image: "https://s3.amazonaws.com/cdn.refabric.com/static/onboarding/Train/Train-Step-3.mp4",
    header: "Name your Training",
    description: "Choose a name for your training model. Later, when creating a new design, you'll pick it from your trainings.",
  },
  {
    image: "https://s3.amazonaws.com/cdn.refabric.com/static/onboarding/Train/Train-Step-4.mp4",
    header: "Select a subject",
    description: "Choose a Training Subject for you to focus on one fashion item.",
  },
  {
    image: "https://s3.amazonaws.com/cdn.refabric.com/static/onboarding/Train/Train-Step-5.mp4",
    header: "Upload your Images",
    description: "Choose at least 5 high-resolution images of the selected subject type. For best results, upload images with the object in focus, and with different contrasts and lighting.",
  },
  {
    image: "https://s3.amazonaws.com/cdn.refabric.com/static/onboarding/Train/Train-Step-6.mp4",
    header: "Click on Start Training",
    description: "",
  },
];

////// EDIT

export const EditImageTutorialData: any = [
  {
    image: "Dummy",
    header: "Dummy",
    description: "Dummy",
  },
  {
    image: "https://s3.amazonaws.com/cdn.refabric.com/static/onboarding/Edit/Edit-Step-1.mp4",
    header: "Choose a Design",
    description: () => (
      <span>
        Choose a style from your <span className="text-green">Design History</span> and click <span className="text-green">Edit</span> or create a new one.
      </span>
    ),
  },
  {
    image: "https://s3.amazonaws.com/cdn.refabric.com/static/onboarding/Edit/Edit-Step-2.mp4",
    header: "Pick your instrument!",
    description: "Use one of the tools to edit your image.",
    content: () => (
      <div className="flex flex-col w-full">
        <div className="flex gap-[5px] text-headline text-white uppercase bg-bg-3 p-2 rounded-t-[5px]">
          <IconTip className="w-[14px] h-[14px]" /> SELECTION TOOLS
        </div>
        <div className="flex flex-col p-3 gap-3 border-b border-x border-bg-3 rounded-b-[5px]">
          <div className="flex gap-2.5 items-center">
            <IconBrush className="text-green flex-shrink-0 w-4 h-4" />
            <span className="text-h7 text-white">
              <b>Brush Tool:</b> Use the brush tool to paint an area.
            </span>
          </div>
          <div className="flex gap-2.5 items-center">
            <IconMagicSelection className="text-green flex-shrink-0 w-4 h-4" />
            <span className="text-h7 text-white">
              <b>Magic Selection:</b> Automatically select an object or area of your image.
            </span>
          </div>
          <div className="flex gap-2.5 items-center">
            <IconLasso className="text-green flex-shrink-0 w-4 h-4" />
            <span className="text-h7 text-white">
              <b>Lasso Tool:</b> Use the lasso tool to form a border around a selected object within your creation.
            </span>
          </div>
        </div>
      </div>
    ),
  },
  {
    image: "https://s3.amazonaws.com/cdn.refabric.com/static/onboarding/Edit/Edit-Step-3.mp4",
    header: "Choose the area from the image.",
    description: "",
  },
  {
    image: "https://s3.amazonaws.com/cdn.refabric.com/static/onboarding/Edit/Edit-Step-4.mp4",
    header: "Pick what you want to edit in the selected area.",
    description: () => (
      <span>
        You can pick an action to help you edit your image. Click <span className="text-green">I want to change the attribute.</span>
      </span>
    ),
  },
  {
    image: "https://s3.amazonaws.com/cdn.refabric.com/static/onboarding/Edit/Edit-Step-5.mp4",
    header: "Write what you want to change in your image.",
    description: () => (
      <span>
        You can describe or add images to clarify your desired image. Write <span className="text-green">one shoulder</span> to define what you want in the selected area.
      </span>
    ),
  },
  {
    image: "https://s3.amazonaws.com/cdn.refabric.com/static/onboarding/Edit/Edit-Step-6.mp4",
    header: "Click on Try",
    description: "",
  },
  {
    image: "https://s3.amazonaws.com/cdn.refabric.com/static/onboarding/Edit/Edit-Step-7.mp4",
    header: "Remove Background",
    description: "You can remove background to focus on your model and the design.",
  },
];
