import { AnalyticsEvent } from "utils";

export function EventUploadLimitError(location: string) {
  const eventName = location + "_upload_limit_error";
  AnalyticsEvent(eventName, "", "click");
}

export function EventLike(location: string) {
  const eventName = location + "_like";
  AnalyticsEvent(eventName, "", "click");
}

export function EventUnlike(location: string) {
  const eventName = location + "_unlike";
  AnalyticsEvent(eventName, "", "click");
}

export function EventSaveCollection(location: string) {
  const eventName = location + "_save_to_collection";
  AnalyticsEvent(eventName, "", "click");
}
