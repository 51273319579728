import { RefabricProURL } from "../index";
import { ApiResponse } from "../HttpClient";
import { IUserInfoRequest, IUserLoginRequest, IUserRegisterRequest, ILikeRequest, IGoogleLogin, IUserEditRequest, IUserOTPLoginRequest } from "./user.type";

export default {
  async checkIsRegistered(data: any): Promise<ApiResponse<any>> {
    return await RefabricProURL.post("users/is_register", data, {});
  },
  async register(data: IUserRegisterRequest): Promise<ApiResponse<any>> {
    return await RefabricProURL.post("users/register", data, {});
  },
  async login(data: IUserLoginRequest): Promise<ApiResponse<any>> {
    return await RefabricProURL.post("users/login", data, {});
  },
  async OTPlogin(data: IUserOTPLoginRequest): Promise<ApiResponse<any>> {
    return await RefabricProURL.post("users/otp", data, {});
  },
  async getUserDetail(params: IUserInfoRequest): Promise<ApiResponse<any>> {
    return await RefabricProURL.get(`users/${params.user_id}`, {});
  },
  async tokenControl(data: { client_id: string }): Promise<ApiResponse<any>> {
    return await RefabricProURL.post("users/token_control", data, {});
  },
  async like(data: ILikeRequest): Promise<ApiResponse<any>> {
    return await RefabricProURL.post("users/favorites", data, {});
  },
  async getFavorites(params: any): Promise<ApiResponse<any>> {
    return await RefabricProURL.get(`users/favorites/${params.object_type}`, {});
  },
  async forgotPassword(data: { email: string }): Promise<ApiResponse<any>> {
    return await RefabricProURL.post(`users/forgot_password`, data, {});
  },
  async resetPassword(token: string, data: { email: string; password: string; password_replay: string }): Promise<ApiResponse<any>> {
    return await RefabricProURL.post(`users/reset_password/${token}`, data, {});
  },
  async verifyEmail(token: string, data: { email: string }): Promise<ApiResponse<any>> {
    return await RefabricProURL.post(`users/verify_email/${token}`, data, {});
  },
  async refreshToken(data: { email: string }): Promise<ApiResponse<any>> {
    return await RefabricProURL.post(`users/refresh_token`, data, {});
  },
  async changePassword(user_id: string, data: { email: string; current_password: string; password: string; password_replay: string }): Promise<ApiResponse<any>> {
    return await RefabricProURL.put(`users/${user_id}/change_password`, data, {});
  },
  async googleLogin(data: IGoogleLogin): Promise<ApiResponse<any>> {
    return await RefabricProURL.post("users/google_login", data, {});
  },
  async editUserDetail(user_id: string, data: IUserEditRequest): Promise<ApiResponse<any>> {
    return await RefabricProURL.put(`users/${user_id}`, data, {});
  },
  async getInvoiceList(): Promise<ApiResponse<any>> {
    return await RefabricProURL.get(`users/invoice_list`, {});
  },
};
