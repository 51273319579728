/* eslint-disable react/no-unescaped-entities */
import React from "react";
import Button from "components/Button";
import useAuthToken from "hooks/useAuthToken";
import { LogoRefabric } from "icons";
import { useNavigate } from "react-router-dom";
import { PATHS } from "router/config/paths";
import { getClearPath } from "utils";

const NotAuthorized = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center h-screen w-screen gap-20 mt-10">
      <LogoRefabric />
      <div className="flex flex-col max-w-[520px] w-full">
        <div className="flex flex-col gap-8">
          <div className="flex flex-col gap-2.5">
            <h2 className="text-h2 text-center text-white">You don't have authorization to acces this page!</h2>
            <span className="text-bodyMd text-grey text-center">This feature is available to logged-in users with associated membership. Please log in or upgrade to right plan for this feature.</span>
          </div>
          <div className="flex gap-5">
            <Button
              className="btn-primary btn-sm w-full"
              onClick={() => {
                useAuthToken.clearAuthTokenFromLocalStorage();
                navigate(getClearPath(PATHS.LOGIN));
              }}
            >
              Go to Login
            </Button>
            <Button
              className="btn-primary btn-sm w-full !bg-purple hover:!bg-pink"
              onClick={() => {
                navigate("/pricing");
              }}
            >
              Go to Pricing
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotAuthorized;
