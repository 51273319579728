import React from "react";
import { MOBILE_WIDTH } from "./useIsMobile";

const TABLET_WIDTH = 834;

export const useIsTablet = ({ maxWidth = MOBILE_WIDTH }: { maxWidth?: number } = {}) => {
  const [isMobile, setIsMobile] = React.useState(MOBILE_WIDTH < screen.width && screen.width < TABLET_WIDTH);

  React.useEffect(() => {
    const handleWindowResize = () => {
      const isMobileViewport = MOBILE_WIDTH < screen.width && screen.width < TABLET_WIDTH;
      setIsMobile(isMobileViewport);
    };

    handleWindowResize(); // Call it initially to ensure the state is correct on mount
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, [maxWidth]);

  return isMobile;
};
