import { IconClose } from "icons";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { resetSnackbar } from "store/commonSlice";

const Snackbar = ({ data, isLastItem }: any) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(true);
  const Icon = data.icon;
  const snackbarRef = useRef<HTMLDivElement | null>(null);
  const [mouseEntered, setMouseEntered] = useState(false);

  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        if (!mouseEntered) {
          setShow(false);
          if (isLastItem) dispatch(resetSnackbar());
        }
      }, 3000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [show, mouseEntered]);

  // const handleMouseEnter = () => {
  //   setMouseEntered(true);
  // };

  // const handleMouseLeave = () => {
  //   setMouseEntered(false);
  // };

  return (
    <div
      ref={snackbarRef}
      // onMouseEnter={handleMouseEnter}
      // onMouseLeave={handleMouseLeave}
      className={`flex flex-col w-fit bg-bg text-h6 text-white rounded-[4px] border border-bg-3 ${show ? "opacity-100" : "opacity-0"} transition-opacity duration-700`}
    >
      <div className="flex items-center gap-2.5 p-2 ">
        {Icon && <Icon className="w-[14px] h-[14px]" />}
        <span className="text-bodySm text-white">{data.message}</span>
        <IconClose className="flex-shrink-0  cursor-pointer w-[14px] h-[14px] text-grey" onClick={() => setShow(false)} />
      </div>
      <div className="h-[1px] rounded-b-full bg-white animate-snackbar"></div>
    </div>
  );
};

export default Snackbar;
